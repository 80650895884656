import React from 'react';
import ItemList from '../components/ItemList';

function MenusKebab() {
  const menusList1 = [
    { name: 'Kebab', description: 'Carne de ternera con patatas fritas y bebida', price: '7,00€' },
    { name: 'Shawarma', description: 'Carne de pollo con patatas fritas y bebida', price: '7,00€' },
    { name: 'Mixto', description: 'Carne de pollo y ternera con patatas fritas y bebida', price: '7,00€' },
    { name: 'Durum', description: 'Carne de pollo o ternera con patatas fritas y bebida', price: '8,00€' },
    { name: 'Pizza Turca', description: 'Pizza con pollo o ternera, incluye patatas fritas y bebida', price: '9,00€' },
  ];

  const menusList2 = [
    { name: 'Kebab', description: 'Carne de ternera, incluye lechuga, tomate, cebolla y salsa', price: '4,00€' },
    { name: 'Shawarma', description: 'Carne de pollo, incluye lechuga, tomate, cebolla y salsa', price: '4,00€' },
    { name: 'Mixto', description: 'Carne de pollo y ternera, incluye lechuga, tomate, cebolla y salsa', price: '4,50€' },
    { name: 'Durum', description: 'Carne de pollo o ternera, incluye lechuga, tomate, cebolla y salsa', price: '5,00€' },
    { name: 'Pizza Turca', description: 'Carne de pollo o ternera, incluye lechuga, tomate, cebolla y salsa', price: '6,50€' },
    { name: 'Taco Casablanca', description: 'Pollo o ternera, carne kebab, lechuga, tomate, cebolla, patatas fritas y salsa yogur', price: '6,00€' },
    { name: 'Taco Pizza', description: 'Pollo, carne kebab, lechuga, tomate, cebolla, patatas fritas y salsa de tomate', price: '7,00€' },
  ];

  return (
    <div id="kebab" className="menu-section">
      <div className="row">
        <h3 id="menus" className="menu-title">Menú</h3>
        <div className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList1.map((menu, index) => (
                <ItemList
                  key={index}
                  name={menu.name}
                  description={menu.description}
                  price={menu.price}
                />
              ))}
            </ul>
          </div>
        </div>

        <h3 id="productos" className="menu-title">Solo Kebab</h3>
        <div className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList2.map((menu, index) => (
                <ItemList
                  key={index}
                  name={menu.name}
                  description={menu.description}
                  price={menu.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="floating-buttons">
        <a href="#menus" className="btn btn-primary">Menú</a>
        <a href="#productos" className="btn btn-secondary">Solo Kebab</a>
      </div>
    </div>
  );
}

export default MenusKebab;
