import React from 'react';
import ItemList from '../components/ItemList';

function Novedades() {

  const novedadesList = [
    { name: 'Bocadillo Kebab', description: 'Kebab, queso, lechuga, patatas y salsa', price: '5,00€' },
    { name: 'Alitas de Pollo', description: '', price: '4,00€' },
    { name: 'Nugget', description: '', price: '4,00€' },
    { name: 'Sandwich Gratinado', description: '', price: '4,00€' },
    { name: 'Sultán Junior', description: 'Patatas gratinadas con kebab', price: '5,00€' },
  ];





  return (
    <div id="paninis" className="menu-section">
      <div className="row">
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {novedadesList.map((novedad, index) => (
                <ItemList
                  key={index}
                  name={novedad.name}
                  description={novedad.description}
                  price={novedad.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Novedades;
