import React from 'react';
import ItemList from '../components/ItemList';
function Bebidas() {

  const menusList6 = [
    { name: 'Refrescos', description: 'Refrescos de 33cl', price: '1,20€', img: "img/soft_drink.jpg" },
    { name: 'Agua', description: 'Botella 50cl', price: '1,00€' },
  ];

  return (
    <div id="paninis" className="menu-section">
      <div className="row">
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList6.map((bebidas, index) => (
                <ItemList
                  key={index}
                  name={bebidas.name}
                  description={bebidas.description}
                  price={bebidas.price}
                  img={bebidas.img}
                />
              ))}
            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Bebidas;
