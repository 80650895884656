import React from 'react';
import ItemList from '../components/ItemList';

function Tarrinas() {
  const menuTarrinaList = [
    { name: 'Tarrina Pequeña', description: 'Con extra de carne y bebida', price: '5,00€' },
    { name: 'Tarrina Mediana', description: 'Con extra de carne y bebida', price: '6,00€' },
    { name: 'Tarrina Grande', description: 'Con extra de carne y bebida', price: '7,00€' },
  ];

  const tarrinaList = [
    { name: 'Tarrina Pequeña', description: 'Patata con carne de kebab o pollo', price: '4,00€' },
    { name: 'Tarrina Mediana', description: 'Patata con carne de kebab o pollo', price: '4,50€' },
    { name: 'Tarrina Grande', description: 'Patata con carne de kebab o pollo', price: '5,50€' },
    { name: 'Tarrina Extra', description: 'Patata con carne de kebab o pollo', price: '7,00€' },
  ];

  return (
    <div id="patatas" className="menu-section">
      <div className="row">

        <h3 className="menu-title">Menú</h3>
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menuTarrinaList.map((menu, index) => (
                <ItemList
                  key={index}
                  name={menu.name}
                  description={menu.description}
                  price={menu.price}
                />
              ))}
            </ul>
          </div>
        </div>

        <h3 className="menu-title">Tarrina</h3>
        <div id="tarrinas" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {tarrinaList.map((tarrina, index) => (
                <ItemList
                  key={index}
                  name={tarrina.name}
                  description={tarrina.description}
                  price={tarrina.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="floating-buttons">
        <a href="#menus" className="btn btn-primary">Menú</a>
        <a href="#tarrinas" className="btn btn-secondary">Solo Tarrinas</a>
      </div>
    </div>
  );
}

export default Tarrinas;
