import React from 'react';
import ItemList from '../components/ItemList';

function Pizzas() {

  const PizzasList = [

    { name: 'Pizza Kebab', description: 'Carne Kebab, queso, tomate y salsa', price: '8,00€' },
    { name: 'Pizza Pollo', description: 'Carne Pollo, queso, tomate y salsa', price: '8,00€' },
    { name: 'Pizza Ternera', description: 'Carne Ternera, queso, tomate y salsa', price: '8,00€' },
    { name: 'Pizza Atún', description: 'Atun, queso y tomate', price: '8,00€' },
    { name: 'Pizza 4 Quesos', description: '', price: '8,00€' }
  ];


  return (
    <div id="paninis" className="menu-section">
      <div className="row">

        <div id="paninnis" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {PizzasList.map((menu, index) => (
                <ItemList
                  key={index}
                  name={menu.name}
                  description={menu.description}
                  price={menu.price}
                />
              ))}
            </ul>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Pizzas;
