import React from 'react';

function Head({ title, subtitle }) {
    const subtitleText = typeof subtitle === 'string' ? subtitle : '';
    return (
        <div class="header-decorative p-3">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 text-end mt-5">
                        <div>
                            <h2>{title}</h2>
                            <h3>{subtitleText}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Head;
