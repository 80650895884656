import React from 'react';
import Head from './Head';

function Footer() {
    return (
        <footer className="footer backFooter p-3">
            <div className="container text-center mb-4">
                <div className="contact-info m-2">

                    <div className='mb-3'>
                        <div class="container">
                            <div class="row">

                                <div class="col-md-12 text-end">
                                    <div>
                                        <h2>CasaBlanca</h2>
                                        <h3>Kebab</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h5>Contacto</h5>
                    <p>
                        <a href="https://www.google.com/maps?q=C.+Alcalde+Alfonso+Mart%C3%ADnez+de+la+Hoz,+10" target="_blank" rel="noopener noreferrer">
                            C. Alcalde Alfonso Martínez de la Hoz, 10
                        </a>
                    </p>
                    <p>Telf: <a href="tel:632367041"> 632367041</a></p>


                </div>

                <div className="opening-hours m-2">
                    <h5>Horario</h5>
                    <p>Lun-Dom ................ 19:30-24:00</p>


                </div>
                <div></div>

            </div>
        </footer>
    );
}

export default Footer;
