import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../cssComponents/Nav.css'; // Descomenta si necesitas estilos personalizados

// Array de objetos para los elementos de navegación
const navItems = [
  { id: 'home', label: 'Novedades', path: '/' },
  { id: 'menusKebab', label: 'Kebab', path: '/menusKebab' },
  { id: 'menusPatatas', label: 'Tarrina', path: '/menusPatatas' },
  { id: 'paninis', label: 'Paninis', path: '/paninis' },
  { id: 'pizzas', label: 'Pizzas', path: '/pizzas' },
  /*  { id: 'bocadillos', label: 'Bocadillos', path: '/bocadillos' }, */
  { id: 'platosCombinados', label: 'Platos Combinados', path: '/platosCombinados' },
  { id: 'hamburguesas', label: 'Hamburguesas', path: '/hamburguesas' },
  { id: 'bebidas', label: 'Bebidas', path: '/bebidas' },
  { id: 'encargos', label: 'Encargos', path: '/encargos' },
];

function Nav() {
  /*Control cambio de color en btn*/
  /*Se recorre con un map en el return*/
  const [activeButton, setActiveButton] = useState('home');

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  return (
    <nav className="nav">
      {/* Primera fila: otros botones */}
      <ul className="nav nav-pills justify-content-center mb-2" id="menu-tabs">
        {navItems
          .filter(({ label }) => label !== 'Encargos')
          .map(({ id, label, path }) => (
            <li key={id} className="nav-item">
              <Link
                to={path}
                className={`nav-link ${activeButton === id ? 'active' : ''
                  } ${label === 'Novedades' ? 'highlight' : ''} `}
                onClick={() => handleButtonClick(id)}
              >
                {label}
              </Link>
            </li>
          ))}
      </ul>

      {/* Segunda fila: botones destacados */}
      <ul className="nav nav-pills justify-content-center mt-1 mb-2 contenedorBTN" id="special-tabs">
        {navItems
          .filter(({ label }) => label === 'Encargos')
          .map(({ id, label, path }) => (
            <li key={id} className="nav-item">
              <Link
                to={path}
                className={`nav-link ${activeButton === id ? 'active' : ''
                  } ${label === 'Novedades' ? 'highlight' : ''} ${label === 'Encargos' ? 'special-highlight' : ''
                  }`}
                onClick={() => handleButtonClick(id)}
              >
                {label}
              </Link>
            </li>
          ))}
      </ul>
    </nav>


  );
}

export default Nav;

