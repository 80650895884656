import React from 'react';
import ItemList from '../components/ItemList';

function Encargos() {

  const menusList2 = [
    { name: 'Pastela', description: 'De pollo y almendras', price: '15,00€' },
    { name: 'Tajín de Carne', description: 'Carne guisada con especias', price: '15,00€' },
    { name: 'Cuscús', description: 'Con verduras y acompañamientos', price: '9,00€' },
  ];


  return (
    <div id="paninis" className="menu-section">
      <div className="row">
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList2.map((hamburguesa, index) => (
                <ItemList
                  key={index}
                  name={hamburguesa.name}
                  description={hamburguesa.description}
                  price={hamburguesa.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Encargos;
