import React from 'react';

function ItemList({ name, description, price, img }) {

  return (
    <li className="d-flex justify-content-between align-items-center">
      {/* <div className="d-flex align-items-center">
        <img
          src={imageUrl || "https://www.unileverfoodsolutions.es/dam/global-ufs/inspiration-&-training/future-menus/2023-modernized-comfort-food/site-and-social-media-/site_banner_1260x700/dish-shots/Site_Banner_1260x700_Yasamine%20Attaman%20recipe.jpg"}
          alt={name}
          className="me-3"
          style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <div>
          <h4>{name}</h4>
          <p>{description}</p>
        </div>

        <div className='price'>
          {price}€
        </div>
      </div> */}
      <div className="d-flex align-items-center">
        <div className='p-2'>
          <img
            src={img || "https://www.unileverfoodsolutions.es/dam/global-ufs/inspiration-&-training/future-menus/2023-modernized-comfort-food/site-and-social-media-/site_banner_1260x700/dish-shots/Site_Banner_1260x700_Yasamine%20Attaman%20recipe.jpg"}
            alt={name}
            style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '6px' }}
          />
        </div>
        <div className="card-body p-2">
          <h4 className="card-title">{name}</h4>
          <p className="card-text">{description}</p>
          <p className="card-text price">{price}</p>
        </div>
      </div>
    </li>

  );
}

export default ItemList;
