import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Nav from './components/Nav';
import Head from './components/Head';
import Home from './pages/Home';
import Pizzas from './pages/Pizzas';
import Bocadillos from './pages/Bocadillos';
import Novedades from './pages/Novedades';
import Paninis from './pages/Paninis';
import MenusKebab from './pages/MenusKebab';
import Tarrinas from './pages/Tarrinas';
import Encargos from './pages/Encargos';
import Hamburguesas from './pages/Hamburguesas';
import Bebidas from './pages/Bebidas';
import PlatosCombinados from './pages/PlatosCombinados';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Head title="CasaBlanca" subtitle="Kebab!" />
      <div className="App container my-5">
        <Nav />
        <a href="tel:+34632367041" class="btn btn-success btn-lg rounded-circle btn-flotante">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg>
        </a>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/novedades" element={<Novedades />} />
            <Route path="/menusKebab" element={<MenusKebab />} />
            <Route path="/menusPatatas" element={<Tarrinas />} />
            <Route path="/paninis" element={<Paninis />} />
            <Route path="/pizzas" element={<Pizzas />} />
            <Route path="/platosCombinados" element={<PlatosCombinados />} />
            <Route path="/hamburguesas" element={<Hamburguesas />} />
            <Route path="/bebidas" element={<Bebidas />} />
            <Route path="/encargos" element={<Encargos />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
