import React from 'react';
import ItemList from '../components/ItemList';

function Paninis() {
  const menusList1 = [
    { name: 'Menú Paninni', description: 'Paninni (cualquier tipo) con patatas y bebida', price: '7,50€' },
  ];

  const menusList2 = [
    { name: 'Paninni Ternera', description: 'Carne de ternera, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
    { name: 'Paninni Pollo', description: 'Carne de pollo, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
    { name: 'Paninni Pavo', description: 'Lonchas de pavo, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
    { name: 'Paninni Mixto', description: 'Carne de ternera y pollo, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
    { name: 'Paninni Salami', description: 'Salami, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
    { name: 'Paninni Hot Dog', description: 'Salchichas, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
    { name: 'Paninni Atún', description: 'Atún y aceitunas, queso, tomate, cebolla, orégano y salsas a elegir', price: '5,00€' },
  ];

  return (
    <div id="paninis" className="menu-section">
      <div className="row">

        <h3 className="menu-title">Menú</h3>
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList1.map((menu, index) => (
                <ItemList
                  key={index}
                  name={menu.name}
                  description={menu.description}
                  price={menu.price}
                />
              ))}
            </ul>
          </div>
        </div>

        <div id="paninnis" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList2.map((menu, index) => (
                <ItemList
                  key={index}
                  name={menu.name}
                  description={menu.description}
                  price={menu.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="floating-buttons">
        <a href="#menus" className="btn btn-primary">Menú</a>
        <a href="#paninnis" className="btn btn-secondary">Solo Paninnis</a>
      </div>
    </div>
  );
}

export default Paninis;
