import React from 'react';
import Novedades from './Novedades';

function Home() {
  return (
    <div>
      {/* <h2 className="textH2">Novedades</h2> */}
      <Novedades />
    </div>
  );
}

export default Home;
