import React from 'react';
import ItemList from '../components/ItemList';

function Hamburguesas() {

  const menusList4 = [
    { name: 'Hamburguesa Normal', description: 'Pollo o ternera, lechuga, tomate y salsa yogur', price: '4,00€' },
    { name: 'Hamburguesa Especial', description: 'Pollo o ternera, carne kebab, huevo, queso, lechuga, tomate y salsa yogur', price: '6,00€' },
  ];



  return (
    <div id="paninis" className="menu-section">
      <div className="row">
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {menusList4.map((hamburguesa, index) => (
                <ItemList
                  key={index}
                  name={hamburguesa.name}
                  description={hamburguesa.description}
                  price={hamburguesa.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hamburguesas;
