import React from 'react';
import ItemList from '../components/ItemList';

function platosCombinados() {

  const platosCombinadosList = [
    { name: 'Sultán', description: 'Patatas gratinadas con kebab', price: '6,50€' },
    { name: 'Plato Combinado', description: 'Ensalada, patatas fritas, carne kebab y salsa yogur', price: '6,50€' },
    { name: 'Sultán Junior', description: 'Patatas gratinadas con kebab', price: '5,00€' },
  ];





  return (
    <div id="platos" className="menu-section">
      <div className="row">
        <div id="menus" className="col-md-6">
          <div className="menu-card">
            <ul className="list-unstyled">
              {platosCombinadosList.map((platocombinado, index) => (
                <ItemList
                  key={index}
                  name={platocombinado.name}
                  description={platocombinado.description}
                  price={platocombinado.price}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default platosCombinados;
